import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/sambrajyam-reddy.jpg";
  const fullName = "Sambrajyam Reddy";
  const zoomUrl = "https://us02web.zoom.us/j/86280060190";
  const zoomPhoneNumber = "(646) 558 8656";
  const zoomMeetingId = "862 8006 0190";
  const birthDate = "October 1st, 1940";
  const deathDate = "November 6th, 2020";
  const memorialDate = "Sunday November 22nd, 2020";
  const memorialTime = "11am EST";
  const inviteText =
    "Raji, a very special woman, mother, grandmother, aunt, teacher, mentor and friend passed from this life to the next upon completing an exceptional life overflowing with love and purpose. Please join us in celebrating the memories of one who has shared life's grace with so many.";
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fqe11rfai";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/riad7onx0c8xj8ob05kuu0qj486dc71ghb1tzqz4";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/raji.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      recordingEmbedUrl={recordingEmbedUrl}
      customColumnSpacing="2fr 3fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    >
      <p>
        In lieu of flowers, we are requesting that people consider{" "}
        <a
          href="https://secure.givelively.org/donate/privilege-to-serve-inc/in-memory-of-raji-reddy"
          target="_blank"
          rel="noopener noreferrer"
        >
          making a donation in her name
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
